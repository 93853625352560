var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout--main"},[_c('vx-sidebar',{attrs:{"sidebarItems":_vm.sidebarItems,"logo":require('@/assets/images/logo/logo_64.png'),"title":"舟山岱山蓬莱社区","parent":".layout--main"}}),_c('div',{class:[_vm.contentAreaClass, { 'show-overlay': _vm.bodyOverlay }],attrs:{"id":"content-area"}},[_c('div',{attrs:{"id":"content-overlay"}}),_c('div',{staticClass:"content-wrapper"},[_c('the-navbar',{class:[
          { 'text-white': _vm.isNavbarDark && !_vm.isThemeDark },
          { 'text-base': !_vm.isNavbarDark && _vm.isThemeDark }
        ],attrs:{"navbarColor":_vm.navbarColor}}),_c('div',{staticClass:"router-view"},[_c('div',{staticClass:"router-content",class:{ 'mt-0': _vm.navbarType == 'hidden' }},[_c('transition',{attrs:{"name":_vm.routerTransition}},[(_vm.$route.meta.breadcrumb || _vm.$route.meta.pageTitle)?_c('div',{staticClass:"router-header flex flex-wrap items-center mb-2"},[_c('div',{staticClass:"content-area__heading",class:{
                  'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light':
                    _vm.$route.meta.breadcrumb
                }},[_c('h4',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.routeTitle))])]),(_vm.$route.meta.breadcrumb)?_c('vx-breadcrumb',{staticClass:"ml-4 md:block hidden"}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"content-area__content"},[_c('transition',{attrs:{"name":_vm.routerTransition,"mode":"out-in"}},[_c('router-view',{on:{"updateNavbarColor":_vm.updateNavbarColor}})],1),(!_vm.hideScrollToTop)?_c('back-to-top',{attrs:{"bottom":"5%","visibleoffset":"500"}},[_c('vs-button',{staticClass:"shadow-lg",attrs:{"icon-pack":"feather","icon":"icon-arrow-up"}})],1):_vm._e()],1)],1)])],1)]),(!_vm.disableThemeTour)?_c('vx-tour',{attrs:{"steps":_vm.steps,"options":_vm.options,"callbacks":_vm.stepCallbacks}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }