<template>
  <div class="layout--main">

    <!-- 侧边栏 -->
    <vx-sidebar
      :sidebarItems="sidebarItems"
      :logo="require('@/assets/images/logo/logo_64.png')"
      title="舟山岱山蓬莱社区"
      parent=".layout--main"
    />

    <!-- 主窗口 -->
    <div
      id="content-area"
      :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]"
    >
      <div id="content-overlay"></div>

      <div class="content-wrapper">
        <!-- 导航条 -->
        <the-navbar
          :navbarColor="navbarColor"
          :class="[
            { 'text-white': isNavbarDark && !isThemeDark },
            { 'text-base': !isNavbarDark && isThemeDark }
          ]"
        />
        <!-- 主窗口动效与分路由 -->
        <div class="router-view">
          <div
            class="router-content"
            :class="{ 'mt-0': navbarType == 'hidden' }"
          >
            <transition :name="routerTransition">
              <!-- 主窗口头部 -->
              <div
                class="router-header flex flex-wrap items-center mb-2"
                v-if="$route.meta.breadcrumb || $route.meta.pageTitle"
              >
                <!-- 路由头 -->
                <div
                  class="content-area__heading"
                  :class="{
                    'pr-4 border-0 md:border-r border-t-0 border-b-0 border-l-0 border-solid border-grey-light':
                      $route.meta.breadcrumb
                  }"
                >
                  <h4 class="mb-1">{{ routeTitle }}</h4>
                </div>
                <!-- 面包屑 -->
                <vx-breadcrumb
                  class="ml-4 md:block hidden"
                  v-if="$route.meta.breadcrumb"
                />
              </div>
            </transition>

            <div class="content-area__content">
              <!-- 主体内容 -->
              <transition :name="routerTransition" mode="out-in">
                <router-view
                  @updateNavbarColor="updateNavbarColor"
                ></router-view>
              </transition>

              <!-- 返回顶部栏 -->
              <back-to-top
                bottom="5%"
                visibleoffset="500"
                v-if="!hideScrollToTop"
              >
                <vs-button
                  icon-pack="feather"
                  icon="icon-arrow-up"
                  class="shadow-lg"
                />
              </back-to-top>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 提示 -->
    <vx-tour :steps="steps" :options="options" :callbacks="stepCallbacks" v-if="!disableThemeTour"/>
  
  </div>
</template>

<script>
import VxSidebar from "@/layouts/components/vx-sidebar/VxSidebar.vue";
import TheNavbar from "@/layouts/components/vx-navbar/TheNavbar.vue";
import BackToTop from "vue-backtotop";

const VxTour = () => import('@/components/VxTour.vue')

export default {
  data() {
    return {
      // 通用的
      navbarType: "floating",
      navbarColor: this.$store.state.navbarColor,
      routerTransition: this.$store.state.routerTransition,
      isNavbarDark: false,
      routeTitle: this.$route.meta.pageTitle,
      sidebarItems: this.$store.state.auth.rmenu,
      windowWidth: window.innerWidth,
      hideScrollToTop: false,

      // 提示框
      disableThemeTour: true,
      options: {
        // seKeyboardNavigation: false,
        startTimeout: 500,  
        labels: {
          buttonSkip: '跳过',
          buttonPrevious: '上一个',
          buttonNext: '下一个',
          buttonStop: '完成'
        }
      },
      stepCallbacks: {
        onPreviousStep: this.finishStepCallback,
        onNextStep: this.finishStepCallback,
        onStart: this.finishStepCallback,
        onFinish: this.finishStepCallback
      },
      steps: [
          {
            target: '#sidebarToggler',
            content: `点击展开 <strong>菜单</strong>!`,
            params: {
              placement: 'bottom'
            }
          },
          {
            target: '#sidebar-village',
            content: `请选择所属 <strong>社区</strong>!`,
            params: {
              placement: 'left'
            }
          }
        ]
    };
  },
  computed: {
    isThemeDark() {
      return this.$store.state.theme == "dark";
    },
    sidebarWidth() {
      return this.$store.state.sidebarWidth;
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay;
    },
    // eslint-disable-next-line vue/return-in-computed-property
    contentAreaClass() {
      if (this.sidebarWidth == "default") return "content-area-default";
      else if (this.sidebarWidth == "reduced") return "content-area-reduced";
      else if (this.sidebarWidth) return "content-area-full";
    }
  },
  created() {
    if (this.navbarColor == "#fff" && this.isThemeDark) {
      this.updateNavbarColor("#10163a");
    } else {
      this.updateNavbarColor(this.navbarColor);
    }
  },
  mounted() {
    this.bus.$on('openTour', ( msg => {
      if (this.$store.state.reduceButton) {
        this.$store.commit("TOGGLE_REDUCE_BUTTON", false);
      }
      this.disableThemeTour = false;
    })
  )},
  methods: {
    updateNavbarColor(val) {
      this.navbarColor = val;
      // 控制导航条文字
      if (val == "#fff") this.isNavbarDark = false;
      else this.isNavbarDark = true;
    },
    finishStepCallback () {
      console.log('提示流程完成回调成功')
    },
  },
  watch: {
    $route() {
      this.routeTitle = this.$route.meta.pageTitle;
      this.routerTransition = localStorage.getItem("routerTransition");
    },
    isThemeDark(val) {
      if (this.navbarColor == "#fff" && val) {
        this.updateNavbarColor("#10163a");
      } else {
        this.updateNavbarColor(localStorage.getItem("navbarColor") || "#fff");
      }
    }
  },
  components: {
    VxSidebar,
    TheNavbar,
    BackToTop,
    VxTour
  }
};
</script>
