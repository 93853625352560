<!-- 导航栏 -->

<template>
  <div class="relative">
    <div class="vx-navbar-wrapper">
      <vs-navbar class="vx-navbar navbar-custom" :color="navbarColor" :class="classObj">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer mr-1" icon="MenuIcon" @click.stop="showSidebar"></feather-icon>

        <template>
          <div class="text-left leading-tight hidden sm:hidden xl:block ">
            <p class="font-semibold">系统时间</p>
            <small>{{ currentTime }}</small>
          </div>
        </template>

        <template>
          <!-- 当前社区/ -->
          <!-- <div class="text-left leading-tight pl-2">
           
          </div> -->
        </template>

        <vs-spacer></vs-spacer>

        <!-- 搜索 -->
        <div class="search-full-container w-full h-full absolute left-0 rounded-lg" :class="{ flex: showFullSearch }" v-show="showFullSearch">
          <vx-auto-suggest class="w-full" inputClassses="w-full vs-input-no-border vs-input-no-shdow-focus no-icon-border" :autoFocus="showFullSearch" :data="navbarSearchAndPinList" icon="SearchIcon" placeholder="Search..." ref="navbarSearch" @closeSearchbar="showFullSearch = false" @selected="selected" background-overlay />
          <div class="absolute right-0 h-full z-50">
            <feather-icon icon="XIcon" class="px-4 cursor-pointer h-full close-search-icon" @click="showFullSearch = false"></feather-icon>
          </div>
        </div>

        <feather-icon icon="SearchIcon" @click="showFullSearch = true" class="cursor-pointer navbar-fuzzy-search ml-4"></feather-icon>

        <!-- 全屏 -->
        <feather-icon icon="MaximizeIcon" @click="FullscreenBtn" class="cursor-pointer ml-4"></feather-icon>

        <!-- USER META -->
        <div class="the-navbar__user-meta flex items-center ml-4">
          <div class="text-right leading-tight hidden sm:block">
            <p class="font-semibold">{{ user_displayName }}</p>
            <small>{{ activeStatus }}</small>
          </div>
          <vs-dropdown vs-custom-content class="cursor-pointer">
            <div class="con-img ml-3">
              <img key="localImg" :src="require(`@/assets/images/portrait/small/${activeUserImg}`)" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
            </div>
            <vs-dropdown-menu class="vx-navbar-dropdown">
              <ul style="min-width: 9rem">
                <!-- <li
                  class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                  @click="$router.push('/profile/userInfo')"
                >
                  <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">用户信息</span>
                </li> -->
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/profile/resetPassword')">
                  <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">重置密码</span>
                </li>
                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="$router.push('/profile/customize')">
                  <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">系统配置</span>
                </li>

                <vs-divider class="m-1"></vs-divider>

                <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
                  <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
                  <span class="ml-2">退出账号</span>
                </li>
              </ul>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import VxAutoSuggest from '@/components/vx-auto-suggest/VxAutoSuggest.vue'

export default {
  name: 'the-navbar',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    }
  },
  data() {
    return {
      // 社区
      // villageSelect: "",
      // villageOptions: [],
      // 通用数据
      navbarSearchAndPinList: this.$store.state.navbarSearchAndPinList,
      currentTime: '',
      searchQuery: '',
      showFullSearch: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      },
      autoFocusSearch: false,
      showBookmarkPagesDropdown: false
    }
  },
  created() {
    // 刷新定时器
    this.getTime()
    setInterval(this.getTime, 1000)
  },
  beforeDestroy() {
    // 移除定时器
    if (this.currentTime) {
      clearInterval(this.currentTime)
    }
  },
  watch: {
    $route() {
      if (this.showBookmarkPagesDropdown) this.showBookmarkPagesDropdown = false
    }
  },
  computed: {
    sidebarWidth() {
      return this.$store.state.sidebarWidth
    },
    breakpoint() {
      return this.$store.state.breakpoint
    },
    // NAVBAR STYLE
    // eslint-disable-next-line vue/return-in-computed-property
    classObj() {
      if (this.sidebarWidth == 'default') return 'navbar-default'
      else if (this.sidebarWidth == 'reduced') return 'navbar-reduced'
      else if (this.sidebarWidth) return 'navbar-full'
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList
    },
    starredPages() {
      return this.$store.state.starredPages
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10)
      },
      set(list) {
        this.$store.dispatch('arrangeStarredPagesLimited', list)
      }
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10)
      },
      set(list) {
        this.$store.dispatch('arrangeStarredPagesMore', list)
      }
    },
    // PROFILE
    user_displayName() {
      let name = JSON.parse(localStorage.getItem('userInfo')).displayName
      name = name ? name : this.$store.state.userInfo.displayName
      return name
    },
    activeUserImg() {
      let avatar = JSON.parse(localStorage.getItem('userInfo')).avatar
      avatar = avatar ? avatar : this.$store.state.userInfo.avatar
      return avatar
    },
    activeStatus() {
      let statusNum = JSON.parse(localStorage.getItem('userInfo')).status
      statusNum = statusNum ? statusNum : this.$store.state.userInfo.status
      return statusNum === 2 ? '在线' : '离线'
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true)
    },
    selected(item) {
      this.$router.push(item.url)
      this.showFullSearch = false
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch('updateStarredPage', {
        index: item.index,
        val: !item.highlightAction
      })
    },
    showNavbarSearch() {
      this.showFullSearch = true
    },
    showSearchbar() {
      this.showFullSearch = true
    },
    elapsedTime(startTime) {
      let x = new Date(startTime)
      let now = new Date()
      var timeDiff = now - x
      timeDiff /= 1000

      var seconds = Math.round(timeDiff)
      timeDiff = Math.floor(timeDiff / 60)

      var minutes = Math.round(timeDiff % 60)
      timeDiff = Math.floor(timeDiff / 60)

      var hours = Math.round(timeDiff % 24)
      timeDiff = Math.floor(timeDiff / 24)

      var days = Math.round(timeDiff % 365)
      timeDiff = Math.floor(timeDiff / 365)

      var years = timeDiff

      if (years > 0) {
        return years + (years > 1 ? ' Years ' : ' Year ') + 'ago'
      } else if (days > 0) {
        return days + (days > 1 ? ' Days ' : ' Day ') + 'ago'
      } else if (hours > 0) {
        return hours + (hours > 1 ? ' Hrs ' : ' Hour ') + 'ago'
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? ' Mins ' : ' Min ') + 'ago'
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? `${seconds} sec ago` : 'just now')
      }

      return 'Just Now'
    },
    logout() {
      const payload = {
        router: this.$router
      }
      this.$store.dispatch('auth/loginOut', payload)
    },
    outside() {
      this.showBookmarkPagesDropdown = false
    },
    // 获取当前时间
    getTime() {
      this.currentTime = this.$cFun.formatTime()
    },
    // 全屏按钮
    FullscreenBtn() {
      console.log(this.IsFull())
      if (this.IsFull()) {
        this.exitFullscreen()
      } else {
        this.launchFullscreen()
      }
    },
    // 判断是否全屏
    IsFull() {
      // var explorer = window.navigator.userAgent.toLowerCase();
      // if (explorer.indexOf("chrome") > 0) {
      //   //webkit
      //   if (
      //     document.body.scrollHeight === window.screen.height &&
      //     document.body.scrollWidth === window.screen.width
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // } else {
      //   //IE 9+  fireFox
      //   if (
      //     window.outerHeight === window.screen.height &&
      //     window.outerWidth === window.screen.width
      //   ) {
      //     return true;
      //   } else {
      //     return false;
      //   }
      // }
      // 这个方法也可以(就是兼容性差了点)
      return document.fullscreen
    },
    // 全屏
    launchFullscreen() {
      let element = document.documentElement
      if (element.requestFullscreen) {
        element.requestFullscreen()
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen()
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen()
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen()
      }
    },
    // 退出全屏
    exitFullscreen() {
      setTimeout(() => {
        if (document.exitFullscreen) {
          document.exitFullscreen()
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen()
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen()
        }
      }, 10)
    }
  },
  directives: {
    'click-outside': {
      bind: function(el, binding) {
        const bubble = binding.modifiers.bubble
        const handler = e => {
          if (bubble || (!el.contains(e.target) && el !== e.target)) {
            binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler
        document.addEventListener('click', handler)
      },

      unbind: function(el) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    }
  },
  components: {
    VxAutoSuggest
  }
}
</script>
