<!-- =========================================================================================
/* 组件参数:
        url     => router path
        name    => name to display in sidebar
        slug    => router path name
        icon    => Feather Icon component/icon name
        tag     => text to display on badge
        tagColor  => class to apply on badge element
        i18n    => Internationalization (废弃)
        submenu   => submenu of current item (current item will become dropdown )
            NOTE: Submenu don't have any icon(you can add icon if u want to display)
        isDisabled  => disable sidebar item/group 
*/  
========================================================================================== -->

<template>
  <div class="parentx">
    <vs-sidebar
      v-hammer:swipe.left="onSwipeLeft"
      ref="mainSidebar"
      :parent="parent"
      :hiddenBackground="clickNotClose"
      :reduce="reduce"
      default-index="-1"
      class="sidebarx main-menu-sidebar items-no-padding"
      v-model="isSidebarActive"
      :click-not-close="clickNotClose"
      :reduce-not-rebound="reduceNotRebound"
    >
      <div @mouseenter="sidebarMouseEntered" @mouseleave="sidebarMouseLeave" class="parentx-content">
        <div
          class="header-sidebar flex items-end justify-between"
          slot="header"
        >
          <div class="logo flex items-center">
            <img :src="logo" alt="logo" class="w-8 mr-6" v-if="logo" />
            <span
              class="logo-text text-primary"
              v-show="isMouseEnter || !reduce"
              v-if="title"
              >{{ title }}</span
            >
          </div>

          <div id="sidebarToggler">
            <template v-if="showCloseButton">
              <feather-icon
                icon="XIcon"
                class="m-0 cursor-pointer"
                @click="$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', false)"
              ></feather-icon>
            </template>
            <template v-else-if="!showCloseButton && !sidebarItemsMin">
              <feather-icon
                icon="DiscIcon"
                class="mr-0 cursor-pointer"
                svg-classes="stroke-current"
                v-show="!reduce"
                @click="toggleReduce(true)"
                id="btnSidebarToggler"
              ></feather-icon>
              <feather-icon
                icon="CircleIcon"
                class="mr-0 cursor-pointer"
                svg-classes="stroke-current"
                v-show="reduce"
                @click="toggleReduce(false)"
                id="btnSidebarToggler"
              ></feather-icon>
            </template>
          </div>
        </div>

        <div class="shadow-bottom" v-show="showShadowBottom"></div>

        <div v-show="!sidebarItemsMin">
          <el-divider class="" content-position="left">岱山社区</el-divider>
        </div>

        <div class="sidebar-village" id="sidebar-village">

          <div class="sidebar-village-content">
            
            <div class="icon-content">
              <vs-icon icon="how_to_vote" size="18px"  class="sidebar-village-icon"></vs-icon>
            </div>

            <div class="sidebar-village-select" v-show="!sidebarItemsMin">
              <!-- 当前社区/ -->
              <vs-select
                class="sidebar_select"
                autocomplete
                v-model="villageSelect"
                placeholder="请选择社区"
                width="190px"
                @click="toggleReduce(false)"
                @change="currentSel"
              >
                <vs-select-item
                  :key="index"
                  :value="item.space_code"
                  :text="item.space_name"
                  v-for="(item, index) in villageOptions"
                  @mouseenter="sidebarMouseEntered"
                />
              </vs-select>
            </div>

          </div>

        </div>

        <div v-show="!sidebarItemsMin">
          <el-divider content-position="left">导航栏</el-divider>
        </div>

        <VuePerfectScrollbar
          ref="mainSidebarPs"
          class="scroll-area--main-sidebar"
          :settings="settings"
          @ps-scroll-y="psSectionScroll"
        >
          <template v-for="(sidebarItem, index) in sidebarItems">
          
            <span
              :key="`header-${index}`"
              v-if="sidebarItem.header && !sidebarItemsMin"
              class="navigation-header truncate"
              >{{ sidebarItem.header }}</span
            >

            <template v-else-if="!sidebarItem.header">
             
            <vx-sidebar-item
              ref="sidebarLink"
              :key="`sidebarItem-${index}`"
              v-if="!sidebarItem.submenu"
              :index="index"
              :to="sidebarItem.slug != 'external' ? sidebarItem.url : ''"
              :href="sidebarItem.slug == 'external' ? sidebarItem.url : ''"
              :icon="sidebarItem.icon"
              :target="sidebarItem.target"
              :isDisabled="sidebarItem.isDisabled"
            >
              <span v-show="!sidebarItemsMin" class="truncate">{{
                sidebarItem.name
              }}</span>
              <vs-chip
                class="ml-auto"
                :color="sidebarItem.tagColor"
                v-if="sidebarItem.tag && (isMouseEnter || !reduce)"
                >{{ sidebarItem.tag }}</vs-chip
              >
            </vx-sidebar-item>

            <template v-else>

              <vx-sidebar-group
                ref="sidebarGrp"
                :key="`group-${index}`"
                :openHover="openGroupHover"
                :group="sidebarItem"
                :groupIndex="index"
                :open="isGroupActive(sidebarItem)"
              ></vx-sidebar-group>
                
            </template>

            </template>

          </template>
        </VuePerfectScrollbar>
        
      </div>
    </vs-sidebar>
    <div
      v-hammer:swipe.right="onSwipeRightSidebarSwipeArea"
      v-if="!isSidebarActive"
      class="sidebar-swipe-area"
      id="sidebar-swipe-area"
    ></div>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import VxSidebarGroup from "./VxSidebarGroup.vue";
import VxSidebarItem from "./VxSidebarItem.vue";

export default {
  name: "vx-sidebar",
  props: {
    sidebarItems: {
      type: Array,
      required: true
    },
    title: {
      type: String
    },
    logo: {
      type: String
    },
    parent: {
      type: String
    },
    openGroupHover: {
      type: Boolean,
      default: false
    },
    reduceNotRebound: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    // 社区
    villageSelect: "",
    villageOptions: [],
    // 通用
    clickNotClose: false, // disable close sidebar on outside click
    reduce: false, // determines if sidebar is reduce - component property
    showCloseButton: false, // show close button in smaller devices
    isMouseEnter: false,
    settings: {
      // perfectscrollbar settings
      maxScrollbarLength: 60,
      wheelSpeed: 1,
      swipeEasing: true
    },
    windowWidth: window.innerWidth, //width of windows
    showShadowBottom: false
  }),
  computed: {
    isSidebarActive: {
      get() {
        return this.$store.state.isSidebarActive;
      },
      set(val) {
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", val);
      }
    },
    reduceSidebar() {
      return Boolean(this.reduce && this.reduceButton);
    },
    reduceButton: {
      get() {
        return this.$store.state.reduceButton;
      },
      set(val) {
        this.$store.commit("TOGGLE_REDUCE_BUTTON", val);
      }
    },
    sidebarItemsMin() {
      return this.$store.state.sidebarItemsMin;
    },
    isGroupActive() {
      return sidebarItem => {
        const path = this.$route.fullPath;
        let open = false;
        let func = function(sidebarItem) {
          if (sidebarItem.submenu) {
            sidebarItem.submenu.forEach(item => {
              if (path == item.url) {
                open = true;
              } else if (item.submenu) {
                func(item);
              }
            });
          }
        };
        func(sidebarItem);
        return open;
      };
    }
  },
  watch: {
    reduce(val) {
      if (val == true) {
        this.$store.dispatch("updateSidebarWidth", "reduced");
      } else {
        this.$store.dispatch("updateSidebarWidth", "default");
      }

      setTimeout(function() {
        window.dispatchEvent(new Event("resize"));
      }, 100);
    },
    reduceButton() {
      this.setSidebarWidth();
    },
    $route() {
      if (this.isSidebarActive && this.showCloseButton)
        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
    }
  },
  methods: {
    // 当前选中的社区
    currentSel(selVal) {
      if (!selVal) return false;
      let selObj = this.villageOptions.filter(
        item => item.space_code == selVal
      );
      this.$store.commit("auth/UPDATE_CURRENT_SPACE", ...selObj);
    },
    // 获取当前用户的社区
    async getVillageList() {
      try {
        let result = await this.$apis.ODC("获取用户区域权限", {});
        if (this.$apis.isOk(result)) {
          this.villageOptions = result.data;
          this.villageSelect = this.$store.state.auth.space.space_code || result.data[0].space_code;
        }
      } catch (err) {
        console.error(err.message ? err.message : err);
      }
    },
    sidebarMouseEntered() {
      if (this.reduce) this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);
      this.isMouseEnter = true;
    },
    sidebarMouseLeave() {
      if (this.reduce) {
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true);
      }
      this.isMouseEnter = false;
    },
    toggleReduce(val) {
      this.reduceButton = val;
      this.setSidebarWidth();
    },
    handleWindowResize(event) {
      this.windowWidth = event.currentTarget.innerWidth;
      this.setSidebarWidth();
    },
    setSidebarWidth() {
      if (this.windowWidth < 1200) {
        if (this.windowWidth < 992)
          this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "md");
        else this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "lg");

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", false);
        if (this.reduceButton) this.reduce = false;
        // this.reduceButton = false;
        this.showCloseButton = true;
        this.clickNotClose = false;
        this.$store.dispatch("updateSidebarWidth", "no-sidebar");
        this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);
      } else {
        this.$store.commit("UPDATE_WINDOW_BREAKPOINT", "xl");
        if (this.reduceButton) this.reduce = true;
        else this.reduce = false;

        this.$store.commit("TOGGLE_IS_SIDEBAR_ACTIVE", true);
        if (this.reduceButton && !this.isMouseEnter)
          this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", true);
        else this.$store.commit("UPDATE_SIDEBAR_ITEMS_MIN", false);

        this.clickNotClose = true;
        this.showCloseButton = false;
        if (this.reduceSidebar)
          this.$store.dispatch("updateSidebarWidth", "reduced");
        else this.$store.dispatch("updateSidebarWidth", "default");
      }
    },
    psSectionScroll() {
      if (this.$refs.mainSidebarPs.$el.scrollTop > 0)
        this.showShadowBottom = true;
      else this.showShadowBottom = false;
    },
    onSwipeLeft() {
      if (this.isSidebarActive && this.showCloseButton)
        this.isSidebarActive = false;
    },
    onSwipeRightSidebarSwipeArea() {
      if (!this.isSidebarActive && this.showCloseButton)
        this.isSidebarActive = true;
    }
  },
  components: {
    VxSidebarGroup,
    VxSidebarItem,
    VuePerfectScrollbar
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.handleWindowResize);
    });
    this.setSidebarWidth();
  },
  created() {
    // 获取社区列表
    this.getVillageList();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleWindowResize);
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/components/vxSidebar.scss";
</style>
